@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Mulish&display=swap');

body {
 font-family: 'Mulish', sans-serif;
}

