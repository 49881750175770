@import url('https://fonts.googleapis.com/css2?family=Mulish&display=swap');

@import '~mdb-ui-kit/css/mdb.min.css';

@import 'react-select-search/style.css';

.App {
  height: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #1a2846;
  min-height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  font-weight: bolder;
  /* color: #7ce9a0; */
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* .geoMap {
  height: 100%;
} */

.container {
  height: 100%;
  width: 100%;
  display: grid;
}

.row {
  width: auto;
  column-gap: 0px;
}

.column {
  float: left;
  text-align: left;
  border: 1px solid rgb(163, 155, 155);
  border-radius: 2px;
  height: 100%;

}

#map2 {
  height: 100%;
  width: 100%;

}

#mapCell {
  height: 100%;
  padding: 0;
}



@media only screen and (max-width: 325px) { 

  #mapCell {
    display:none
  }
}

.geoMap {
  height: 100%;
  width: 100%;

}


.marker-icon {
  background-position: center;
  background-size: 50px 50px;
  border-radius: 50%;
  height: 50px;
  left: 4px;
  position: absolute;
  text-align: center;
  top: 3px;
  background-image: url('./Components/pinPoint.png');
  background-repeat: no-repeat;
  width: 50px;
}

.userMarker1 {
  background-position: center;
  background-size: 50px 50px;
  border-radius: 50%;
  height: 50px;
  left: 4px;
  position: absolute;
  text-align: center;
  top: 3px;
  background-image: url('./map-pin.svg');
  background-repeat: no-repeat;
  width: 50px;
}

.userMarker1 {
  background-position: center;
  background-size: 50px 50px;
  border-radius: 50%;
  height: 50px;
  left: 4px;
  position: absolute;
  text-align: center;
  background-image: url('./map-pin.svg');
  top: 3px;
  background-repeat: no-repeat;
  width: 50px;
  /* color: rgba(102, 110, 214, 0.384) ;
  color-scheme: rgba(88, 124, 224, 0.384)  */
}

.marker {
  height: 60px;
  width: 60px;
}

.marker-container {
  width: max-content;
  height: 62px;
}

.marker-image {
  height: 55px;
  width: 55px;
  position: absolute;
  left: 4px;
  top: 3px;

}

.marker-content {
  background: #c30b82;
  border-radius: 50% 50% 50% 0;
  height: 60px;
  left: 50%;
  margin: -15px 0 0 -15px;
  position: absolute;
  top: 50%;
  width: 60px;
}

.marker-content::before {
  background: #ffffff;
  border-radius: 50%;
  content: "";
  height: 50px;
  margin: 3px 0 0 3px;
  position: absolute;
  width: 50px;
}

#plusButton {
  cursor: pointer;
  transition: 'background-color';
  transition-duration: 0.3s;

}

#plusButton:hover {
  background-color: rgba(73, 236, 9, 0.26);

}

#clockPng{
  filter: invert();
}

#timeButton {
  cursor: pointer;
  transition: 'background-color';
  transition-duration: 0.3s;
 
}

#timeButton:hover {
  background-color: rgba(73, 236, 9, 0.26);
}

.super-app-theme--header {
  background-color: rgb(228,161,27);
}

#checkButton {
  cursor: pointer;
  transition: 'border-color';
  transition-duration: 0.3s;
  border:2px solid #ffffff ;
  border-radius: 2px;
}

#checkButton:hover {
  border-color: #e8622442;

}

#searchBar {
  height: 25px;
  width: 32dvw;
  padding: 2px ;
  padding-left: 5px;
  margin-bottom: 2px;
  
}

#searchBarClear {
  transition: 'background-color';
  transition-duration: 0.3s;
}

#searchBarClear :hover {
  background-color: rgba(73, 236, 9, 0.26);
}

#orderColumn {
  overflow-y: scroll;
  display:flexbox;
}

#progressColumn{
  overflow-y: scroll;
  overflow-x: hidden;
  display: flexbox;
}

/* progress card / bar styling */

#form {
  text-align: left;
  position: relative;
  margin-top: 0px;
  width: 500px;
}

#form fieldset {
  background: white;
  border: 0 none;
  border-radius: 0.5rem;
  box-sizing: border-box;
  width: 25%;
  margin: 0;
  padding-bottom: 20px;
  position: relative
}

.finish {
  text-align: center
}

#form fieldset:not(:first-of-type) {
  display: none
}

#form .previous-step, .next-step {
  width: 100px;
  font-weight: bold;
  color: white;
  border: 0 none;
  border-radius: 0px;
  cursor: pointer;
  padding: 5px 5px;
  margin: 10px 5px 10px 0px;
  float: right
}

.form, .previous-step {
  background: #616161;
}

.form, .next-step {
  background: #2F8D46;
}

#form .previous-step:hover,
#form .previous-step:focus {
  background-color: #000000
}

#form .next-step:hover,
#form .next-step:focus {
  background-color: #2F8D46
}

.text {
  color: #2F8D46;
  font-weight: normal;
  text-emphasis-position: over;

}

#progressbar {
  margin-bottom: 30px;
  overflow: hidden;
  color: lightgrey;
  width: 60%;
  margin-left: -4%;
}

#progressbar .active {
  color: #2F8D46
}

#progressbar li {
  list-style-type: none;
  font-size: 12px;
  width: 30%;
  float: left;
  position: relative;
  font-weight: 300;
  margin-left: -5%;
}

#progressbar .step1:before {
  content: "1";
  
}

#progressbar .step2:before {
  content: "2";
  

}

#progressbar .step3:before {
  content: "3";
  

}

#progressbar .step4:before {
  content: "";
  
}

#progressbar .step1:after {
  z-index: 0;
}

#progressbar .step2:after {
  z-index: 0;
}

#progressbar .step3:after {
  z-index: 0;
}

#progressbar .step4:after {
  z-index: 0;
}


#progressbar .step4:after {
  width: 0px;
 
}

#progressbar li:before {
  width: 20px;
  height: 20px;
  line-height: 15px;
  display: block;
  font-size: 15px;
  color: #ffffff;
  background: lightgray;
  border-radius: 50%;
  margin: 0 80px 10px auto;
  padding: 2px;
  text-align: center;
}

#progressbar li:after {
  content: '';
  width: 100%;
  height: 2px;
  background: lightgray;
  position: absolute;
  left: 3px;
  top: 10px;
  z-index: 0;
  /* display: -webkit-inline-flex; */

}

#progressbar li.active:before,
#progressbar li.active:after {
  background: #2F8D46;
  z-index: 0;

}

.progress {
  height: 0px
}

.progress-bar {
  background-color: #2F8D46
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loading-spinner {
  width: 50px;
  height: 50px;
  border: 10px solid #f3f3f3; /* Light grey */
  border-top: 10px solid #383636; /* Black */
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
}

#rc-anchor-container {
    width: '70%';

}